
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import Loader from '@/components/Loader.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import StepperHeader from '@/components/stepper/StepperHeader.vue';
import { vatStatuses } from '@/config/constants';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { DeepNullable, MapStateToComputed } from '@/helpers/types';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';
import { analyticsIdentify, analyticsTrack } from '@/services/analytics';
import { sendCompleteUserRegistrationWithCompany } from '@/services/auth';
import { sendGetAllProfessionalactivitiesRequest } from '@/services/professionalactivitiesService';
import { getSchools } from '@/services/schools';
import { sendGetAllSocialsecretariatsRequest } from '@/services/socialsecretariat';
import { InitializedUseraccountNotCompletedRegistrationAuthState } from '@/store/modules/auth';
import PickTier from '@/views/registration/completeUserRegistration/components/PickTier.vue';
import dayjs from 'dayjs';
import {
    IProfessionalactivityViewModel,
    ISchoolViewModel,
    ISocialsecretariatViewModel,
    completeRegistrationWithCompanyData,
    BILLING_PLAN_TYPE
} from 'dexxter-types';
import { capitalize, cloneDeep } from 'lodash';
import mixins from 'vue-typed-mixins';
import { mapState } from 'vuex';
import OnboardingLayout from '../../../../layouts/OnboardingLayout.vue';
import CompleteUserRegistrationWithCompanyNumberSubformsPart1 from '../forms/completeUserRegistrationWithCompanyNumberSubforms/completeUserRegistrationWithCompanyNumberSubformsPart1.vue';
import CompleteUserRegistrationWithCompanyNumberSubformsPart1FormWrapper from '../forms/completeUserRegistrationWithCompanyNumberSubforms/completeUserRegistrationWithCompanyNumberSubformsPart1FormWrapper.vue';
import CompleteUserRegistrationWithCompanyNumberSubformsPart2 from '../forms/completeUserRegistrationWithCompanyNumberSubforms/completeUserRegistrationWithCompanyNumberSubformsPart2.vue';
import CompleteUserRegistrationWithCompanyNumberSubformsPart2FormWrapper from '../forms/completeUserRegistrationWithCompanyNumberSubforms/completeUserRegistrationWithCompanyNumberSubformsPart2FormWrapper.vue';
import CompleteUserRegistrationWithoutCompanyNumberForm from '../forms/completeUserRegistrationWithoutCompanyNumberForm.vue';
import { createCompleteRegistrationWithCompanySubmitData } from '../models/completeRegistrationWithoutCompany';

export default mixins(formRulesMixin, lookupCompanynumberMixin).extend({
    components: {
        Loader,
        OnboardingLayout,
        ConfirmationDialog,
        SubmitButton,
        PickTier,
        StepperHeader,
        CompleteUserRegistrationWithoutCompanyNumberForm,
        CompleteUserRegistrationWithCompanyNumberSubformsPart2,
        CompleteUserRegistrationWithCompanyNumberSubformsPart1,
        CompleteUserRegistrationWithCompanyNumberSubformsPart1FormWrapper,
        CompleteUserRegistrationWithCompanyNumberSubformsPart2FormWrapper
    },

    mixins: [formRulesMixin, lookupCompanynumberMixin],

    data() {
        return {
            formValid: false as boolean,
            schools: [] as ISchoolViewModel[],
            socialSecretariats: null as null | ISocialsecretariatViewModel[],
            professionalactivities: null as IProfessionalactivityViewModel[] | null,
            registrationData:
                createCompleteRegistrationWithCompanySubmitData() as DeepNullable<completeRegistrationWithCompanyData>,
            completeRegistrationStep: 1 as number,
            confirmVATTypeDialog: false as boolean,
            loading: {
                fetchSocialsecretariat: false,
                handleCompleteRegistration: false
            } as {
                fetchSocialsecretariat: boolean;
                handleCompleteRegistration: boolean;
            },
            formPart1Valid: false,
            formPart2Valid: false,
            registrationSteps: [
                { i18nPath: 'completeUserRegistration.chooseTier' },
                { i18nPath: 'completeUserRegistration.vatInformation' },
                { i18nPath: 'completeUserRegistration.businessInformation' }
            ]
        };
    },

    computed: {
        ...(mapState('auth', [
            'currentUserData'
        ]) as MapStateToComputed<InitializedUseraccountNotCompletedRegistrationAuthState>),

        vatLiableConfirmDialogText(): string {
            if (this.registrationData.vatLiable === vatStatuses.VATLIABLE) {
                return `${this.$i18n.t('completeUserRegistration.confirm_vatLiable')}`;
            } else if (this.registrationData.vatLiable === vatStatuses.VATEXEMPT) {
                return `${this.$i18n.t('completeUserRegistration.confirm_exempt_vatLiable')}`;
            } else if (this.registrationData.vatLiable === vatStatuses.NOTVATLIABLE) {
                return `${this.$i18n.t('completeUserRegistration.confirm_not_vatLiable')}`;
            }

            return '';
        },

        isLoading(): boolean {
            return this.loading.fetchSocialsecretariat || this.loading.handleCompleteRegistration;
        }
    },

    created() {
        this.fetchData();
    },

    methods: {
        navigateBack() {
            this.completeRegistrationStep -= 1;
        },
        navigateToPart2() {
            this.completeRegistrationStep += 1;
            this.confirmVATTypeDialog = false;
        },
        handleSubmitPart1() {
            this.confirmVATTypeDialog = true;
        },
        handleSubmitPart2() {
            this.completeRegistrationStep += 1;
        },
        selectTier(selectedUserType: any): void {
            this.registrationData.subscriptionType = 'pro_tier';

            if (selectedUserType === 'main') {
                this.registrationData.role = 'main';
            } else if (selectedUserType === 'secondary') {
                this.registrationData.role = 'secondary';
            } else if (selectedUserType === 'student') {
                this.registrationData.role = 'student';
            }

            this.completeRegistrationStep += 1;
        },
        async fetchData(): Promise<void> {
            this.loading.fetchSocialsecretariat = true;
            await Promise.all([
                sendGetAllSocialsecretariatsRequest()
                    .then((data) => {
                        this.socialSecretariats = data
                            .map((ss) => {
                                return {
                                    ...ss,
                                    name: capitalize(ss.name)
                                };
                            })
                            .sort(this.sortByNameAsc);
                    })
                    .finally(() => {
                        this.loading.fetchSocialsecretariat = false;
                    }),
                sendGetAllProfessionalactivitiesRequest(this.currentUserData.userinformation.language).then(
                    (result) => {
                        this.professionalactivities = result;
                    }
                ),
                getSchools().then((result) => {
                    this.schools = result;
                })
            ]);
        },
        async handleSubmit(): Promise<void> {
            try {
                this.loading.handleCompleteRegistration = true;
                await sendCompleteUserRegistrationWithCompany(cloneDeep(this.registrationData));

                // Refetch currentUserData & permissions
                const [currentUserData] = await Promise.all([
                    this.$store.dispatch('auth/getCurrentUser'),
                    this.$store.dispatch('auth/getPermissions')
                ]);

                notify.call(this, {
                    title: this.$t('completeUserRegistration.succesful_completed'),
                    text: this.$t('completeUserRegistration.succesful_completed')
                });

                const eventProps = {
                    Promo_Code: this.registrationData.promocode,
                    Mode: 'met ondernemingsnummer',
                    Plan_Type: 'trial' as BILLING_PLAN_TYPE | 'trial',
                    User_Type: this.registrationData.role,
                    Vat_Type: this.registrationData.vatLiable,
                    Social_Insurance_fund: this.socialSecretariats?.find(
                        (_ss) => _ss.id === this.registrationData.socialsecretariatId
                    )?.name,
                    Enterprise_Postal_Code: this.registrationData.address.zipcode?.toString(),
                    Company_Number: this.registrationData.company.number?.toString()
                };

                const userProps = {
                    Trial_Expires_At: dayjs().add(30, 'days').toISOString(),
                    Promo_Code: this.registrationData.promocode,
                    Birth_Date: this.registrationData.birthDate,
                    Enterprise_Street_Name: this.registrationData.address.street,
                    Enterprise_House_Number: this.registrationData.address.number,
                    Enterprise_Postal_Code: this.registrationData.address.zipcode?.toString(),
                    Enterprise_City: this.registrationData.address.city,
                    Knows_Us_From: this.registrationData.knowsUsFrom,
                    Plan_Type: 'trial' as const,
                    Company_Number: this.registrationData.company.number,
                    Social_Insurance_Fund: this.socialSecretariats
                        ?.find((_ss) => _ss.id === this.registrationData.socialsecretariatId)
                        ?.name?.toLowerCase(),
                    Enterprise_Start: this.registrationData.companyStartDate,
                    Dexxter_Start: this.registrationData.dexxterStartDate,
                    Vat_Type: this.registrationData.vatLiable,
                    Completed_Registration: true
                };

                analyticsIdentify(this.currentUserData.id, userProps);
                analyticsTrack('User Completes Registration', eventProps);

                this.$gtmTrackEvent({
                    event: 'completed-user-registration',
                    userId: currentUserData.id,
                    email: currentUserData.userinformation.email,
                    firstName: currentUserData.userinformation.firstName,
                    lastName: currentUserData.userinformation.name
                });

                this.$router.replace({ name: 'dashboard' });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading.handleCompleteRegistration = false;
            }
        },
        sortByNameAsc(a: ISocialsecretariatViewModel, b: ISocialsecretariatViewModel): number {
            return a.name >= b.name ? 1 : -1;
        }
    }
});
